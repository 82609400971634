
import userStatuses from "@/constants/userStatuses";
import { CustomStatusesModule } from "@/store/modules/memberCustomStatus";
import { programActivitiesModule } from "@/store/modules/memberProgramActivity";
import { PresencesModule } from "@/store/modules/presences";
import Markup from "@/components/Markup";

import { defineComponent } from "vue";
export default defineComponent({
  name: "UserStatusTemplate",
  components: { Markup },
  props: {
    id: {
      type: String,
      required: true,
    },
    showStatusOnly: {
      type: Boolean,
      required: false,
    },
    showOffline: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    presence(): any {
      const presence = PresencesModule.getPresence(this.id);
      if (!this.showOffline && !presence) return undefined;
      return userStatuses[presence || 0];
    },
    customStatus(): any {
      return CustomStatusesModule.customStatus[this.id];
    },
    gameStatus(): any {
      return programActivitiesModule.programActivity[this.id];
    },
  },
});
